import React, { ChangeEvent, Fragment, RefObject, SyntheticEvent, useEffect, useRef, useState } from 'react';
import './App.css';
import { Button, CssBaseline, Drawer, IconButton, Typography, useMediaQuery, Box, Divider, useTheme, createTheme, ThemeProvider, PaletteOptions, Theme, Autocomplete, TextField, RadioGroup, Radio, FormControlLabel, Tabs, Tab } from '@mui/material';
import { createBrowserRouter, Link, Outlet } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import { ChevronLeft, LightModeOutlined, NightlightOutlined } from '@mui/icons-material';
import useResizeObserver from '@react-hook/resize-observer';
import * as perspective from '@finos/perspective';
import '@finos/perspective-viewer-datagrid';
import '@finos/perspective-viewer-d3fc';
import '@finos/perspective-viewer';
import { HTMLPerspectiveViewerElement } from '@finos/perspective-viewer';
import "@finos/perspective-viewer/dist/css/themes.css";
import { DateRangePicker, defaultTheme, Provider } from '@adobe/react-spectrum'
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import * as fs from 'fs';
import { today, getLocalTimeZone, startOfMonth, startOfYear } from '@internationalized/date';
import * as aq from 'arquero';
import { op } from 'arquero';
import moment from 'moment';
import ColumnTable from 'arquero/dist/types/table/column-table';
import * as embed from 'vega-embed';
// //@ts-ignore
// import * as loader from 'vega-loader-arrow';

// //@ts-ignore
// embed.vega.formats('arrow', loader);

XLSX.set_fs(fs);

const worker = perspective.default.shared_worker();
const ColorModeContext = React.createContext({ toggleColorMode: () => { } });
aq.addFunction('parse_moment_date', (x:number) => moment(x).format('DD MMM YY'), { override: true });

interface PerspectivePaletteOptions extends PaletteOptions {
  materialMode: string;
}

type PerspectiveTheme = Theme & { palette: PerspectivePaletteOptions };

const fetchData = async (url: string, viewerRef: RefObject<HTMLPerspectiveViewerElement>, viewerConfig: any) => {

  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  const table = await worker.table(buffer);

  if (viewerRef.current) {
    await viewerRef.current.load(table);
    await viewerRef.current.restore(viewerConfig as any);
  }
}

const csvToExcel = async (view?: perspective.View) => {
  const csv = await view?.to_csv();
  const workbook = XLSX.read(csv, { type: 'string' });
  XLSX.writeFileXLSX(workbook, 'untitled.xlsx', { compression: true });
}

const downloadExcel = async (viewerRef: RefObject<HTMLPerspectiveViewerElement>) => {
  const view = await viewerRef.current?.getView();
  csvToExcel(view);
};

const downloadExcelHelper = async (viewerRef: RefObject<HTMLPerspectiveViewerElement[]>, i: number) => {
  const view = await (viewerRef.current![i]).getView();
  csvToExcel(view);
};

function SideMenu() {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => setOpen(!!!open);
  const ref = useRef(null);
  const [width, setWidth] = useState<number>(0);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = useState<'light' | 'dark'>(prefersDarkMode ? 'dark' : 'light');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          materialMode: mode === 'light' ? 'Pro Light' : 'Pro Dark'
        } as PerspectivePaletteOptions,
      }),
    [mode],
  );

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );


  useResizeObserver(ref, (entry) => setWidth(entry.contentRect.width));

  const items = [
    {
      flag: '🇯🇵',
      children: [
        { path: `tyo-si`, shortName: 'SI', longName: 'Short Interest' },
        { path: `tyo-bb`, shortName: 'BB', longName: 'Buybacks' },
        { path: `tyo-bb1`, shortName: 'BB1', longName: 'TDNET Ann'},
        { path: `tyo-ch`, shortName: 'CH', longName: 'Cross Holdings' },
        { path: `tyo-chs`, shortName: 'CHS', longName: 'Cross Holdings Summary' },
        { path: `tyo-on`, shortName: 'ON', longName: 'Ownership' },
        { path: `tyo-blk`, shortName: 'BLK', longName: 'Blocks' },
      ]
    },
    {
      flag: '🇭🇰',
      children: [
        { path: `hkg-ah`, shortName: 'AH', longName: 'AH Premium' },
        { path: `hkg-ah1`, shortName: 'AH1', longName: 'AH Premium' },
        { path: `hkg-bb`, shortName: 'BB', longName: 'Buybacks' },
      ]
    },
    {
      flag: '🇺🇸',
      children: [
        { path: `nyc-13`, shortName: '13', longName: 'Form 13' }
      ]
    },
    {
      flag: '🇦🇺',
      children: [
        { path: `syd-gss`, shortName: 'GSS', longName: 'Gross Shorts' },
        { path: `syd-spr`, shortName: 'SPR', longName: 'Short Positions' }
      ]
    }
  ]

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Drawer variant='permanent' open={open} sx={{ textAlign: 'center' }} elevation={0}>
          <Box width='100%' onClick={toggleDrawer} display='flex' ref={ref}>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              sx={{ marginLeft: 'auto', ...(!open && { marginRight: 'auto' }) }}
            >
              {(open) ? <ChevronLeft /> : <MenuIcon />}
            </IconButton>
          </Box>
          {
            items.map(item =>
              <React.Fragment key={item.flag}>
                <Divider />
                <Typography variant='h5'>{item.flag}</Typography>
                {
                  item.children.map(child =>
                    <React.Fragment key={child.path}>
                      <Button component={Link} to={child.path}>{(open) ? child.longName : child.shortName}</Button>
                    </React.Fragment>
                  )
                }
              </React.Fragment>
            )
          }
          <Divider />
          <Box width={'100%'}>
            <IconButton onClick={colorMode.toggleColorMode}>
              {(theme.palette.mode === 'dark') ? <LightModeOutlined /> : <NightlightOutlined />}
            </IconButton>
          </Box>
        </Drawer>
        <Box sx={{ marginLeft: `${width}px` }}>
          <Outlet />
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

function TyoSI() {
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();
  const [filters, setFilters] = useState<any>([]);
  const [uploaded, setUploaded] = useState('');

  const viewerConfig = {
    plugin: 'Datagrid',
    theme: theme.palette.materialMode,
    plugin_config: {
      columns: {
        'New Date': {
          dateStyle: 'medium',
          timeStyle: 'disabled'
        },
        'Old Date': {
          dateStyle: 'medium',
          timeStyle: 'disabled'
        },
        'Rep Date': {
          dateStyle: 'medium',
          timeStyle: 'disabled'
        },
        'Old Short Pos': {
          fixed: 0,
        },
        'Short Pos': {
          fixed: 0,
        },
        '__ROW_PATH__': {
          column_size_override: 230,
        },
        'Name of Stock (English)': {
          column_size_override: 175,
        }
      },
    },
    group_by: ['Ticker', 'Name of Short Seller'],
    columns: [
      'Name of Stock (English)',
      'New Date',
      'Old Date',
      'Rep Date',
      'Short Pos',
      'Short Ratio %',
      'Old Short Pos',
      'Old Short Ratio %',
      'Days of ADV (90d)',
      'Total Value (US$ mm)',
      'Notes',
    ],
    filter: filters,
    sort: [
      ['Ticker', 'asc'],
      ['New Date', 'desc']
    ],
    expressions: [
      '//Ticker\nstring("Code of Stock")',
      '//New Date\n"Date of Calculation"',
      '//Old Date\n"Date of Calculation in Previous Reporting"',
      '//Short Pos\n"Number of Short Positions in Shares"',
      '//Short Ratio %\n"Ratio of Short Positions to Shares Outstanding"*100',
      '//Old Short Ratio %\n"Ratio of Short Positions in Previous Reporting\xa0"*100',
      '//Rep Date\n"Reporting Date"',
    ],
    aggregates: {
      'Name of Stock (English)': 'unique',
      'New Date': 'unique',
      'Old Date': 'unique',
      'Short Pos': 'sum',
      'Short Ratio %': 'sum',
      'Old Short Ratio %': 'sum',
      'Old Short Pos': 'sum',
      'Notes': 'unique',
      'Rep Date': 'unique'
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = 'api/tyo_si';

    const response = await fetch(url);
    setUploaded(response.headers.get('uploaded') || '');
    const buffer = await response.arrayBuffer();
    const table = await worker.table(buffer);

    if (viewerRef.current) {
      await viewerRef.current.load(table);
      await viewerRef.current.restore(viewerConfig as any);
    }

    const view = await table.view({
      columns: ['Name of Stock (English)'],
      group_by: ['Code of Stock'],
      aggregates: {
        'Name of Stock (English)': 'any',
      },
    });
    const columns = await view.to_json();
    setOptions(columns.slice(1) as any);
  }

  const [options, setOptions] = useState<any[]>([]);

  useEffect(() => {
    viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  }, [viewerConfig]);

  const filterTickers = (e: SyntheticEvent, val: any[]) => {
    if (val.length > 0) {
      setFilters([['Ticker', 'in', val.map(x => x.__ROW_PATH__.toString()).concat('')]]);
    } else {
      setFilters([]);
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography align='center' variant='h2' >JPX Short Interest</Typography>
      <Autocomplete
        sx={{
          margin: '1em auto 1em auto',
          width: '30%'
        }}
        multiple
        options={options}
        getOptionLabel={(option) => `${option.__ROW_PATH__}: ${option['Name of Stock (English)']}`}
        filterSelectedOptions
        onChange={filterTickers}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Tickers...'
          />
        )}
      />
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        <perspective-viewer style={{ height: '75vh' }} ref={viewerRef} />
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
      <Typography align='right' variant='caption' display={'block'} marginY={0}>{`(Last updated: ${uploaded})`}</Typography>
    </Box>
  )
}

function TyoBb() {
  // const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  // const theme: PerspectiveTheme = useTheme();

  // const viewerConfig = {
  //   plugin: 'Datagrid',
  //   theme: theme.palette.materialMode,
  //   plugin_config: {
  //     columns: {
  //       'Announcement Date': {
  //         dateStyle: 'medium',
  //         timeStyle: 'disabled',
  //       },
  //       'Programme Start': {
  //         dateStyle: 'medium',
  //         timeStyle: 'disabled',
  //       },
  //       'Programme End': {
  //         dateStyle: 'medium',
  //         timeStyle: 'disabled',
  //       },
  //       'Record Start': {
  //         dateStyle: 'medium',
  //         timeStyle: 'disabled',
  //       },
  //       'Record End': {
  //         dateStyle: 'medium',
  //         timeStyle: 'disabled',
  //       },
  //       'Share Target': {
  //         fixed: 0
  //       },
  //       'Shares Bought': {
  //         fixed: 0
  //       },
  //       'JPY Bought (mn)': {
  //         fixed: 0
  //       },
  //       'JPY Target (mn)': {
  //         fixed: 0
  //       }
  //     },
  //   },
  //   split_by: [

  //   ],
  //   columns: [
  //     'Ticker',
  //     'Name',
  //     'Announcement Date',
  //     'Programme Start',
  //     'Programme End',
  //     'Share Target',
  //     'JPY Target (mn)',
  //     'Record Start',
  //     'Record End',
  //     'Shares Bought',
  //     'JPY Bought (mn)',
  //   ],
  //   sort: [
  //     ['Record End', 'desc'],
  //     ['Record Start', 'desc'],
  //     ['Ticker', 'asc']
  //   ],
  //   expressions: [
  //     '//Ticker\n"scode"',
  //     '//Name\n"eng_name"',
  //     '//Announcement Date\n"ann_date"',
  //     '//Programme Start\n"xfrom"',
  //     '//Programme End\n"xto"',
  //     '//Share Target\n"p_share_count"',
  //     '//JPY Target (mn)\n("p_share_value")/1e6',
  //     '//Record Start\n"rdate_from"',
  //     '//Record End\n"rdate_to"',
  //     '//Shares Bought\n"r_share_count"',
  //     '//JPY Bought (mn)\n("r_share_value")/1e6',
  //   ]
  // }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   const url = './tyo_bb.feather';

  //   const response = await fetch(url);
  //   const buffer = await response.arrayBuffer();
  //   const table = await worker.table(buffer);

  //   if (viewerRef.current) {
  //     await viewerRef.current.load(table);
  //     await viewerRef.current.restore(viewerConfig as any);
  //   }
  // }

  // useEffect(() => {
  //   viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  // }, [viewerConfig]);

  // const [dateRange, setDateRange] = useState<any>(null);

  // const handleDateChange = (x: any) => setDateRange(x);

  // const respondButton = (type: string) => {
  //   const tday = today(getLocalTimeZone());
  //   switch (type) {
  //     case '1W':
  //       setDateRange({ end: tday, start: tday.subtract({ weeks: 1 }) });
  //       break;
  //     case 'MTD':
  //       setDateRange({ end: tday, start: startOfMonth(tday) });
  //       break;
  //     case '3M':
  //       setDateRange({ end: tday, start: tday.subtract({ months: 3 }) });
  //       break;
  //     case 'YTD':
  //       setDateRange({ end: tday, start: startOfYear(tday) });
  //       break;
  //     case '1Y':
  //       setDateRange({ end: tday, start: tday.subtract({ years: 1 }) });
  //       break;
  //     case 'All':
  //       setDateRange({ end: null, start: null });
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // return (
  //   <Box sx={{ width: '100%' }} position='relative'>
  //     <Typography align='center' variant='h2' >Japan Buybacks</Typography>
  //     <Box display='flex' flexDirection={'row'} width='fit-content' gap='1em' margin='auto' marginBottom={'1em'}>
  //       <Provider theme={defaultTheme} colorScheme={theme.palette.mode} locale='en-UK-u-ca-iso8601'>
  //         <DateRangePicker aria-label='Date Range' value={dateRange} onChange={handleDateChange} />
  //       </Provider>
  //       {
  //         ['1W', 'MTD', '3M', 'YTD', '1Y', 'All'].map(x =>
  //           <Fragment key={x}>
  //             <Button onClick={() => respondButton(x)}>{x}</Button>
  //           </Fragment>
  //         )
  //       }
  //     </Box>
  //     <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
  //       <perspective-viewer style={{ height: '75vh' }} ref={viewerRef} />
  //       <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
  //         <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
  //       </Button>
  //     </Box>
  //   </Box>
  // )
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();
  const [dt, setData] = useState<ColumnTable>();
  const [dateRange, setDateRange] = useState<any>({end: null, start: null});
  const tabs = ['Shares Bought', 'JPY Bought (mn)'];
  const [tab, setTab] = useState(tabs[0]);
  const [options, setOptions] = useState<{scode: string, name: string}[]>([]);
  const [filters, setFilters] = useState<Set<string>>();
  const [uploaded, setUploaded] = useState('');

  const viewerConfig = {
    plugin: 'Datagrid',
    theme: theme.palette.materialMode,
    plugin_config: {
      columns: {
        fixed: 1,
      },
    },
    columns: [
    ]
  }

  const fetchData = async () => {
    const url = './tyo_bb.feather';

    const response = await fetch(url);
    setUploaded(response.headers.get('uploaded') || '');
    const buffer = await response.arrayBuffer();
    setData(aq.fromArrow(buffer));
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dt) {
      setOptions(dt.orderby('scode').rollup({ rows: d => op.array_agg_distinct(op.row_object('scode', 'name')) }).get('rows', 0));
    }
  }, [dt]);

  const makeTable = async () => {
    if (dt) {
      const buffer = dt
                  .derive({
                    Ticker: d => d!!['scode'],
                    'Name': d => d!!['name'],
                    'Ann Date': d => (op as any).parse_moment_date(d!!['ann_date']),
                    'Prog Start': d => (op as any).parse_moment_date(d!!['xfrom']),
                    'Prog End': d => (op as any).parse_moment_date(d!!['xto']),
                    'Share Target': d => d!!['p_share_count'],
                    'JPY Target (mn)': d => d!!['p_share_value']/1e6,
                    'JPY Bought (mn)': d => d!!['r_share_value']/1e6,
                    'Shares Bought': d => d!!['r_share_count'],
                  })
                  .filter(aq.escape((d:any) => !filters || (filters.size == 0) || op.has(filters, d['scode'])))
                  .filter(aq.escape((d:any) => (!dateRange.end || d['rdate'] <= dateRange.end.toDate().getTime()) && (!dateRange.start || d['rdate'] >= dateRange.start.toDate().getTime())))
                  .groupby(['Ticker', 'Name', 'Ann Date', 'Prog Start', 'Prog End', 'Share Target', 'JPY Target (mn)'])
                  .derive({date:  d => (op as any).parse_moment_date(d!!['rdate'])})
                  .orderby('rdate')
                  .pivot('date', tab, {sort: false})
                  .orderby('Ticker')
                  .toArrowBuffer().buffer;

      const table = await worker.table(buffer);

      if (viewerRef.current) {
        await viewerRef.current.load(table);
        await viewerRef.current.restore(viewerConfig as any);
      }
    }
  };

  useEffect(() => {
    makeTable();
  }, [dt, dateRange, tab, filters])


  useEffect(() => {
    viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  }, [viewerConfig]);

  const handleDateChange = (x: any) => setDateRange(x);

  const respondButton = (type: string) => {
    const tday = today(getLocalTimeZone());
    switch (type) {
      case '1W':
        setDateRange({ end: tday, start: tday.subtract({ weeks: 1 }) });
        break;
      case 'MTD':
        setDateRange({ end: tday, start: startOfMonth(tday) });
        break;
      case '3M':
        setDateRange({ end: tday, start: startOfMonth(tday.subtract({ months: 3 })) });
        break;
      case 'YTD':
        setDateRange({ end: tday, start: startOfYear(tday) });
        break;
      case '1Y':
        setDateRange({ end: tday, start: startOfMonth(tday.subtract({ years: 1 })) });
        break;
      case 'All':
        setDateRange({ end: null, start: null });
        break;
      default:
        break;
    }
  }

  const tabChange = (event: SyntheticEvent, newValue: string) => setTab(newValue);

  const filterTickers = (e: SyntheticEvent, val: any[]) => {
    setFilters(new Set(val.map(x => x['scode'])));
  }


  return (
    <Box sx={{ width: '100%' }} position='relative'>
      <Typography align='center' variant='h2' >Japan Buybacks</Typography>
      <Autocomplete
        sx={{
          margin: '1em auto 1em auto',
          width: '30%'
        }}
        multiple
        options={options}
        getOptionLabel={(option) => `${option['scode']}: ${option['name']}`}
        filterSelectedOptions
        onChange={filterTickers}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Tickers...'
          />
        )}
      />
      <Box display='flex' flexDirection={'row'} width='fit-content' gap='1em' margin='auto' marginBottom={'1em'}>
        <Provider theme={defaultTheme} colorScheme={theme.palette.mode} locale='en-UK-u-ca-iso8601'>
          <DateRangePicker aria-label='Date Range' value={dateRange} onChange={handleDateChange} />
        </Provider>
        {
          ['3M', 'YTD', '1Y', 'All'].map(x =>
            <Fragment key={x}>
              <Button onClick={() => respondButton(x)}>{x}</Button>
            </Fragment>
          )
        }
       </Box>
       <Box paddingX='2.5em' marginBottom={'1em'}>
         <Tabs value={tab} onChange={tabChange}>
          {
            tabs.map(x => 
                <Tab value={x} label={x} key={x}/>
            )
          }
         </Tabs>
       </Box>
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        <perspective-viewer style={{ height: '58vh' }} ref={viewerRef} />
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
      <Typography align='right' variant='caption' display={'block'} marginY={0}>{`(Last updated: ${uploaded})`}</Typography>
    </Box>
  )
}

function TyoCh() {
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();
  const [filter, setFilter] = useState<any>([
    ['Holder Ticker', 'is not null', ''],
    ['Holding Ticker', 'is not null', ''],
    ['Type', 'is not null', '']
  ]);

  const viewerConfig = {
    plugin: 'Datagrid',
    theme: theme.palette.materialMode,
    plugin_config: {
      columns: {
        'Shares': {
          fixed: 0
        },
        'Book Value (mn)': {
          fixed: 0
        },
        'Curr Value (mn)': {
          fixed: 0
        },
      }
    },
    filter: filter,
    columns: [
      'Holding Ticker',
      'Holding Name',
      'Holder Ticker',
      'Holder Name',
      'Shares',
      'Book Value (mn)',
      'Curr Value (mn)',
      'Type'
    ],
    expressions: [
      '//Holding Ticker\n"holding_ticker"',
      '//Holding Name\n"holding_name"',
      '//Holder Ticker\n"holder_ticker"',
      '//Holder Name\n"holder_name"',
      '//Shares\n"n_shares"',
      '//Book Value (mn)\n"book_value"/1e6',
      '//Curr Value (mn)\n"curr_value"/1e6',
      '//Type\n"type"'
    ]
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = './tyo_ch.feather';

    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    const table = await worker.table(buffer);

    if (viewerRef.current) {
      await viewerRef.current.load(table);
      await viewerRef.current.restore(viewerConfig as any);
    }

    const holdingView = await table.view({
      columns: ['holding_name'],
      group_by: ['holding_ticker'],
      aggregates: {
        'holding_name': 'first',
      },
    });
    const holdingColumns = await holdingView.to_json();
    setHoldingOptions(holdingColumns.slice(1) as any);

    const holderView = await table.view({
      columns: ['holder_name'],
      group_by: ['holder_ticker'],
      aggregates: {
        'holder_name': 'first',
      },
    });
    const holderColumns = await holderView.to_json()
    setHolderOptions(holderColumns.slice(1) as any);
  }

  const [holderOptions, setHolderOptions] = useState<any[]>([]);
  const [holdingOptions, setHoldingOptions] = useState<any[]>([]);

  const onTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const type = (e.target.value as any);
    if (type === 'Both') {
      setFilter([...filter.slice(0, 2), ['Type', 'is not null', '']]);
    } else {
      setFilter([...filter.slice(0, 2), ['Type', '==', type]]);
    }
  };

  const filterHoldings = (e: SyntheticEvent, val: any[]) => {
    if (val.length === 0) {
      setFilter([filter[0], ['Holding Ticker', 'is not null', ''], filter[2]]);
    } else {
      setFilter([filter[0], ['Holding Ticker', 'in', val.map(x => x.__ROW_PATH__.toString()).concat('')], filter[2]]);
    }
  }

  const filterHolders = (e: SyntheticEvent, val: any[]) => {
    if (val.length === 0) {
      setFilter([['Holder Ticker', 'is not null', ''], ...filter.slice(1, 2)]);
    } else {
      setFilter([['Holder Ticker', 'in', val.map(x => x.__ROW_PATH__.toString()).concat('')], ...filter.slice(1, 2)]);
    }
  }


  useEffect(() => {
    viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  }, [viewerConfig]);

  return (
    <Box sx={{ width: '100%' }} position='relative'>
      <Typography align='center' variant='h2' >Yuho Crossholdings</Typography>
      <Box display='flex' flexDirection={'row'} gap='1em' margin={'1em'} marginLeft={'auto'} marginRight={'auto'} width='fit-content'>
        <Autocomplete
          sx={{ width: '30em' }}
          multiple
          options={holdingOptions}
          getOptionLabel={(option) => `${option.__ROW_PATH__}: ${option.holding_name}`}
          filterSelectedOptions
          onChange={filterHoldings}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Holding Tickers...'
            />
          )}
        />
        <Autocomplete
          sx={{ width: '30em' }}
          multiple
          options={holderOptions}
          getOptionLabel={(option) => `${option.__ROW_PATH__}: ${option.holder_name}`}
          filterSelectedOptions
          onChange={filterHolders}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Holder Tickers...'
            />
          )}
        />
        <RadioGroup row defaultValue={'Both'} onChange={onTypeChange}>
          <FormControlLabel value='Both' label='Both' control={<Radio />} />
          <FormControlLabel value='Deemed' label='Deemed' control={<Radio />} />
          <FormControlLabel value='Strategic' label='Strategic' control={<Radio />} />
        </RadioGroup>
      </Box>
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        <perspective-viewer style={{ height: '75vh' }} ref={viewerRef} />
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
    </Box>
  )
}

function TyoOn() {
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();
  const [filter, setFilter] = useState<any>([]);

  const viewerConfig = {
    plugin: 'Datagrid',
    theme: theme.palette.materialMode,
    plugin_config: {
      columns: {
        'Govt #': {
          fixed: 0
        },
        'Fins #': {
          fixed: 0
        },
        'Brokers #': {
          fixed: 0
        },
        'Corps #': {
          fixed: 0
        },
        'Foreign Insto #': {
          fixed: 0
        },
        'Foreign Individuals #': {
          fixed: 0
        },
        'Retail #': {
          fixed: 0
        },
        'Total #': {
          fixed: 0
        },
        'Govt Shs': {
          fixed: 0
        },
        'Fins Shs': {
          fixed: 0
        },
        'Brokers Shs': {
          fixed: 0
        },
        'Corps Shs': {
          fixed: 0
        },
        'Foreign Insto Shs': {
          fixed: 0
        },
        'Foreign Individuals Shs': {
          fixed: 0
        },
        'Retail Shs': {
          fixed: 0
        },
        'Total Shs': {
          fixed: 0
        },
        'Odd Lots Shs': {
          fixed: 0
        },
        'Treasury': {
          fixed: 0
        },
      }
    },
    filter: filter,
    columns: [
      'Ticker',
      'Name',
      'Govt #',
      'Fins #',
      'Brokers #',
      'Corps #',
      'Foreign Insto #',
      'Foreign Individuals #',
      'Retail #',
      'Total #',
      'Govt Shs',
      'Fins Shs',
      'Brokers Shs',
      'Corps Shs',
      'Foreign Insto Shs',
      'Foreign Individuals Shs',
      'Retail Shs',
      'Total Shs',
      'Odd Lots Shs',
      'Treasury',
      'Govt %',
      'Fins %',
      'Brokers %',
      'Corps %',
      'Foreign Insto %',
      'Foreign Individuals %',
      'Retail %',
    ],
    sort: [
      ['Ticker', 'asc']
    ],
    expressions: [
      '//Ticker\n"ticker"',
      '//Name\n"name"',
      '//Govt #\n"OrdinaryShareMemberNumberOfShareholdersNationalAndLocalGovernments"',
      '//Fins #\n"OrdinaryShareMemberNumberOfShareholdersFinancialInstitutions"',
      '//Brokers #\n"OrdinaryShareMemberNumberOfShareholdersFinancialServiceProviders"',
      '//Corps #\n"OrdinaryShareMemberNumberOfShareholdersOtherCorporations"',
      '//Foreign Insto #\n"OrdinaryShareMemberNumberOfShareholdersForeignInvestorsOtherThanIndividuals"',
      '//Foreign Individuals #\n"OrdinaryShareMemberNumberOfShareholdersForeignIndividualInvestors"',
      '//Retail #\n"OrdinaryShareMemberNumberOfShareholdersIndividualsAndOthers"',
      '//Total #\n"OrdinaryShareMemberNumberOfShareholdersTotal"',
      '//Govt Shs\n"OrdinaryShareMemberNumberOfSharesHeldNumberOfUnitsNationalAndLocalGovernments"*"OrdinaryShareMemberNumberOfSharesConstitutingOneUnit"',
      '//Fins Shs\n"OrdinaryShareMemberNumberOfSharesHeldNumberOfUnitsFinancialInstitutions"*"OrdinaryShareMemberNumberOfSharesConstitutingOneUnit"',
      '//Brokers Shs\n"OrdinaryShareMemberNumberOfSharesHeldNumberOfUnitsFinancialServiceProviders"*"OrdinaryShareMemberNumberOfSharesConstitutingOneUnit"',
      '//Corps Shs\n"OrdinaryShareMemberNumberOfSharesHeldNumberOfUnitsOtherCorporations"*"OrdinaryShareMemberNumberOfSharesConstitutingOneUnit"',
      '//Foreign Insto Shs\n"OrdinaryShareMemberNumberOfSharesHeldNumberOfUnitsForeignInvestorsOtherThanIndividuals"*"OrdinaryShareMemberNumberOfSharesConstitutingOneUnit"',
      '//Foreign Individuals Shs\n"OrdinaryShareMemberNumberOfSharesHeldNumberOfUnitsForeignIndividualInvestors"*"OrdinaryShareMemberNumberOfSharesConstitutingOneUnit"',
      '//Retail Shs\n"OrdinaryShareMemberNumberOfSharesHeldNumberOfUnitsIndividualsAndOthers"*"OrdinaryShareMemberNumberOfSharesConstitutingOneUnit"',
      '//Total Shs\n"OrdinaryShareMemberNumberOfSharesHeldNumberOfUnitsTotal"*"OrdinaryShareMemberNumberOfSharesConstitutingOneUnit"',
      '//Odd Lots Shs\n"OrdinaryShareMemberNumberOfSharesHeldSharesLessThanOneUnit"*"OrdinaryShareMemberNumberOfSharesConstitutingOneUnit"',
      '//Treasury\n"OrdinarySharesSharesWithFullVotingRightsTreasurySharesEtcMemberNumberOfSharesIssuedSharesVotingRights"',
      '//Govt %\n"OrdinaryShareMemberPercentageOfShareholdingsNationalAndLocalGovernments"*100',
      '//Fins %\n"OrdinaryShareMemberPercentageOfShareholdingsFinancialInstitutions"*100',
      '//Brokers %\n"OrdinaryShareMemberPercentageOfShareholdingsFinancialServiceProviders"*100',
      '//Corps %\n"OrdinaryShareMemberPercentageOfShareholdingsOtherCorporations"*100',
      '//Foreign Insto %\n"OrdinaryShareMemberPercentageOfShareholdingsForeignersOtherThanIndividuals"*100',
      '//Foreign Individuals %\n"OrdinaryShareMemberPercentageOfShareholdingsForeignersOtherThanIndividuals"*100',
      '//Retail %\n"OrdinaryShareMemberPercentageOfShareholdingsIndividualsAndOthers"*100',
    ]
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = './tyo_on.feather';

    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    const table = await worker.table(buffer);

    if (viewerRef.current) {
      await viewerRef.current.load(table);
      await viewerRef.current.restore(viewerConfig as any);
    }

    const tickerView = await table.view({
      group_by: ['ticker'],
      columns: ['name'],
      aggregates: {
        'name': 'first'
      }
    });
    const tickerColumns = await tickerView.to_json();
    setOptions(tickerColumns.slice(1) as any);
  }

  const [options, setOptions] = useState<{ __ROW_PATH__: number, name: string }[]>([]);

  useEffect(() => {
    viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  }, [viewerConfig]);

  const filterTicker = (e: SyntheticEvent, vals: { __ROW_PATH__: number, name: string }[]) => {
    if (vals.length === 0) {
      setFilter([])
    } else {
      setFilter([['Ticker', 'in', vals.map(x => x.__ROW_PATH__.toString()).concat('')]]);
    }
  };

  return (
    <Box sx={{ width: '100%' }} position='relative'>
      <Typography align='center' variant='h2' >Yuho Ownership</Typography>
      <Box display='flex' flexDirection={'row'} gap='1em' margin={'1em'} marginLeft={'auto'} marginRight={'auto'} width='fit-content'>
        <Autocomplete
          sx={{ width: '30em' }}
          multiple
          options={options}
          getOptionLabel={(option) => `${option.__ROW_PATH__}: ${option.name}`}
          filterSelectedOptions
          onChange={filterTicker}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Tickers...'
            />
          )}
        />
      </Box>
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        <perspective-viewer style={{ height: '75vh' }} ref={viewerRef} />
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
    </Box>
  )
}

function HkgAh() {
  const [filters, setFilters] = useState<string[]>();
  const theme: PerspectiveTheme = useTheme();

  const viewerConfigs = [
    {
      title: '1 Week Change in Spread',
      plugin: 'X/Y Scatter',
      theme: theme.palette.materialMode,
      group_by: ['hCode'],
      split_by: ['Industry'],
      columns: ([
        'H Discount to A',
        'Spread Change (1 Week)',
        null,
        null,
        null,
        'Name',
        'Z-Score',
      ] as unknown as string[]),
      expressions: [
        '//H Discount to A\n"premium"',
        '//Spread Change (1 Week)\n"spreadChange1W"',
        '//Industry\n"industry"',
        '//Name\n"constituentName"',
        '//Z-Score\nround("52wk Z-Score Rich vs Cheap"*100)/100',
      ],
      aggregations: {
        'H Discount to A': 'any',
        'Spread Change (1 Week)': 'any',
        'Industry': 'any',
        'Name': 'any',
        'Z-Score': 'any'
      },
    },
    {
      title: '4 Week Change in Spread',
      plugin: 'X/Y Scatter',
      theme: theme.palette.materialMode,
      group_by: ['hCode'],
      split_by: ['Industry'],
      columns: ([
        'H Discount to A',
        'Spread Change (4 Week)',
        null,
        null,
        null,
        'Name',
        'Z-Score',
      ] as unknown as string[]),
      expressions: [
        '//H Discount to A\n"premium"',
        '//Spread Change (4 Week)\n"spreadChange4W"',
        '//Industry\n"industry"',
        '//Name\n"constituentName"',
        '//Z-Score\nround("52wk Z-Score Rich vs Cheap"*100)/100',
      ],
      aggregations: {
        'H Discount to A': 'any',
        'Spread Change (1 Week)': 'any',
        'Industry': 'any',
        'Name': 'any',
        'Z-Score': 'any'
      },
    },
    {
      title: 'Overview',
      plugin: 'DataGrid',
      theme: theme.palette.materialMode,
      plugin_config: {
        columns: {
          'Z-Score': {
            'bg_gradient': 2,
            'number_bg_mode': 'gradient'
          },
          'Z-Score(-)': {
            'fg_gradient': 2,
            'number_fg_mode': 'bar'
          },
          'Z-Score(+)': {
            'fg_gradient': 2,
            'number_fg_mode': 'bar'
          },
          '5d Chg': {
            'bg_gradient': 10,
            'number_bg_mode': 'gradient'
          },
          '20d Chg': {
            'bg_gradient': 10,
            'number_bg_mode': 'gradient'
          },
          scroll_lock: true,
        },
      },
      group_by: ['industry', 'constituentName'],
      columns: [
        'HK Ticker',
        'CH Ticker',
        'S',
        'HK Last Price',
        '5d Rtn',
        '1mo Rtn',
        'China Last',
        'USD ADV (mn)',
        'H vs A discount',
        '5d Chg',
        '20d Chg',
        'Z-Score(-)',
        'Z-Score',
        'Z-Score(+)',
      ],
      expressions: [
        '//HK Ticker\n"hCode"',
        '//CH Ticker\n"aCodeTagged"',
        '//Z-Score\n"52wk Z-Score Rich vs Cheap"',
        '//Z-Score(-)\nmin("52wk Z-Score Rich vs Cheap", 0)',
        '//Z-Score(+)\nmax("52wk Z-Score Rich vs Cheap", 0)',
        '//S\nis_not_null("shortAllowed")',
        '//E\nis_not_null("exemptFromTickRule")'
      ],
      aggregates: {
        '5d Rtn': 'mean',
        '1mo Rtn': 'mean',
        'H vs A discount': 'mean',
        '5d Chg': 'mean',
        '20d Chg': 'mean',
        'Z-Score': 'mean',
        'Z-Score(-)': 'mean',
        'Z-Score(+)': 'mean',
        'USD ADV (mn)': 'mean',
        'HK Ticker': 'unique',
        'CH Ticker': 'unique',
        'S': 'unique',
        'China Last': 'unique',
        'HK Last Price': 'unique',
      }
    },
    {
      title: 'Southbound',
      plugin: 'DataGrid',
      theme: theme.palette.materialMode,
      plugin_config: {
        columns: {
          'SB 5D % Vol': {
            'bg_gradient': 10,
            'number_bg_mode': 'gradient',
          },
          'SB 20D % Vol': {
            'bg_gradient': 10,
            'number_bg_mode': 'gradient',
          },
        }
      },
      group_by: ['industry', 'constituentName'],
      columns: [
        'HK Ticker',
        'SB Holding',
        'SB 5D Chg',
        'SB 20D Chg',
        'SB 5D % Vol',
        'SB 20D % Vol'
      ],
      expressions: [
        '//HK Ticker\n"hCode"',
      ],
      aggregates: {
        'HK Ticker': 'unique',
        'SB Holding': 'mean',
        'SB 5D Chg': 'mean',
        'SB 20D Chg': 'mean',
        'SB 5D % Vol': 'mean',
        'SB 20D % Vol': 'mean',
      },
    },
    {
      title: '20d AH Premium',
      plugin: 'X/Y Line',
      theme: theme.palette.materialMode,
      split_by: ['Name'],
      columns: [
        'Date',
        '20d Spread Evolution'
      ],
      filter: filters,
      expressions: [
        '//Name\n"constituentName"',
        '//20d Spread Evolution\n"20d AH Premium"-1'
      ],
    },
  ];

  const viewers = useRef([] as HTMLPerspectiveViewerElement[]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = './hkg_ah.feather';

    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    const table = await worker.table(buffer);

    viewerConfigs.forEach(async (x, i) => {
      const viewer = viewers.current[i];
      if (viewer) {
        await viewer.load(table);
        await viewer.restore(x as {});
      }
    });
  }

  useEffect(() => {
    const handlePerspectiveClick = (e: any) => { setFilters(e.detail.config.filter); };
    viewers.current[3].addEventListener('perspective-click', handlePerspectiveClick);
    return () => viewers.current[3].removeEventListener('perspective-click', handlePerspectiveClick);
  }, [viewers]);

  useEffect(() => {
    viewers.current.forEach((v, i) => {
      v.getTable(false).then(() => v.restore(viewerConfigs[i] as any)).catch(() => { })
    });
  }, [theme]);

  useEffect(() => {
    viewers.current[4].getTable(false).then(() => viewers.current[4].restore(viewerConfigs[4] as any), () => { })
  }, [filters]);

  return (
    <Box>
      <Typography align='center' variant='h2'>A Shares vs H Shares</Typography>
      <Box marginLeft={'10%'} marginRight={'10%'} display='flex' flexDirection={'column'}>
        {
          viewerConfigs.map((x, i) =>
            <React.Fragment key={`perspective${i}`}>
              <Typography variant='h4' marginTop={'2%'} marginBottom={'2%'} textAlign='center'>{x.title}</Typography>
              <perspective-viewer style={{ minHeight: '500px', flex: 1, width: '100%' }} ref={e => {
                if (viewers.current && e) {
                  viewers.current[i] = e
                }
              }} />
              <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcelHelper(viewers, i)}>
                <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
              </Button>
            </React.Fragment>
          )
        }
      </Box>
    </Box>
  );
}

function HkgBb() {
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();
  const [dt, setData] = useState<ColumnTable>();
  const [dateRange, setDateRange] = useState<any>({end: null, start: null});
  const tabs = ['HKD Amt (mn)', 'HKD Avg Price', 'Vlm (%)', '5D Vlm (%)'];
  const [tab, setTab] = useState(tabs[0]);
  const [options, setOptions] = useState<{'Stock code': string, Company: string}[]>([]);
  const [filters, setFilters] = useState<Set<string>>();
  const [uploaded, setUploaded] = useState('');

  const viewerConfig = {
    plugin: 'Datagrid',
    theme: theme.palette.materialMode,
    plugin_config: {
      columns: {
        fixed: 1,
      },
    },
    columns: [
    ]
  }

  const fetchData = async () => {
    const url = '/api/hkg_bb';

    const response = await fetch(url);
    setUploaded(response.headers.get('uploaded') || '');
    const buffer = await response.arrayBuffer();
    setData(aq.fromArrow(buffer));
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dt) {
      setOptions(dt.orderby('ycodes').rollup({ rows: d => op.array_agg_distinct(op.row_object('Stock code', 'Company')) }).get('rows', 0));
    }
  }, [dt]);

  const makeTable = async () => {
    if (dt) {
      const buffer = dt
                  .derive({
                    Ticker: d => op.padstart(d!!['Stock code'], 4, '0'),
                    'Sec Type': d => d!!['Sec. type'], 
                    'HKD Amt (mn)': d => d!!['HKD Amount']/1e6,
                    'Vlm (%)': d => d!!['% of Daily Volume']*100,
                    '5D Vlm (%)': d => d!!['% of Avg 5D Volume']*100,
                    'HKD Avg Price': d => d!!['HKD Average Price Paid']
                  })
                  .filter(aq.escape((d:any) => !filters || (filters.size == 0) || op.has(filters, d['Stock code'])))
                  .filter(aq.escape((d:any) => (!dateRange.end || d['Trading date (yyyy/mm/dd)'] <= dateRange.end.toDate().getTime()) && (!dateRange.start || d['Trading date (yyyy/mm/dd)'] >= dateRange.start.toDate().getTime())))
                  .groupby(['Ticker', 'Company', 'Sec Type'])
                  .derive({date:  d => (op as any).parse_moment_date(d!!['Trading date (yyyy/mm/dd)'])})
                  .pivot('date', tab, {sort: false})
                  .orderby('Ticker')
                  .toArrowBuffer().buffer;

      const table = await worker.table(buffer);

      if (viewerRef.current) {
        await viewerRef.current.load(table);
        await viewerRef.current.restore(viewerConfig as any);
      }
    }
  };

  useEffect(() => {
    makeTable();
  }, [dt, dateRange, tab, filters])


  useEffect(() => {
    viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  }, [viewerConfig]);

  const handleDateChange = (x: any) => setDateRange(x);

  const respondButton = (type: string) => {
    const tday = today(getLocalTimeZone());
    switch (type) {
      case '1W':
        setDateRange({ end: tday, start: tday.subtract({ weeks: 1 }) });
        break;
      case 'MTD':
        setDateRange({ end: tday, start: startOfMonth(tday) });
        break;
      case '3M':
        setDateRange({ end: tday, start: tday.subtract({ months: 3 }) });
        break;
      case 'YTD':
        setDateRange({ end: tday, start: startOfYear(tday) });
        break;
      case '1Y':
        setDateRange({ end: tday, start: tday.subtract({ years: 1 }) });
        break;
      case 'All':
        setDateRange({ end: null, start: null });
        break;
      default:
        break;
    }
  }

  const tabChange = (event: SyntheticEvent, newValue: string) => setTab(newValue);

  const filterTickers = (e: SyntheticEvent, val: any[]) => {
    setFilters(new Set(val.map(x => x["Stock code"])));
  }


  return (
    <Box sx={{ width: '100%' }} position='relative'>
      <Typography align='center' variant='h2' >HKEX Mainboard Buybacks</Typography>
      <Autocomplete
        sx={{
          margin: '1em auto 1em auto',
          width: '30%'
        }}
        multiple
        options={options}
        getOptionLabel={(option) => `${option['Stock code']}: ${option['Company']}`}
        filterSelectedOptions
        onChange={filterTickers}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Tickers...'
          />
        )}
      />
      <Box display='flex' flexDirection={'row'} width='fit-content' gap='1em' margin='auto' marginBottom={'1em'}>
        <Provider theme={defaultTheme} colorScheme={theme.palette.mode} locale='en-UK-u-ca-iso8601'>
          <DateRangePicker aria-label='Date Range' value={dateRange} onChange={handleDateChange} />
        </Provider>
        {
          ['1W', 'MTD', '3M', 'YTD', '1Y', 'All'].map(x =>
            <Fragment key={x}>
              <Button onClick={() => respondButton(x)}>{x}</Button>
            </Fragment>
          )
        }
       </Box>
       <Box paddingX='2.5em' marginBottom={'1em'}>
         <Tabs value={tab} onChange={tabChange}>
          {
            tabs.map(x => 
                <Tab value={x} label={x} key={x}/>
            )
          }
         </Tabs>
       </Box>
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        <perspective-viewer style={{ height: '58vh' }} ref={viewerRef} />
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
      <Typography align='right' variant='caption' display={'block'} marginY={0}>{`(Last updated: ${uploaded})`}</Typography>
    </Box>
  )
}

function Nyc13() {
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();

  const viewerConfig = {
    plugin: 'Datagrid',
    theme: theme.palette.materialMode,
    plugin_config: {
      columns: {

      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = './nyc_13.feather';

    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    const table = await worker.table(buffer);

    if (viewerRef.current) {
      await viewerRef.current.load(table);
      await viewerRef.current.restore(viewerConfig as any);
    }
  }

  useEffect(() => {
    viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  }, [viewerConfig]);

  return (
    <Box sx={{ width: '100%' }} position='relative'>
      <Typography align='center' variant='h2' >Form 13</Typography>
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        <perspective-viewer style={{ height: '75vh' }} ref={viewerRef} />
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
    </Box>
  )
}

function TyoBb1() {
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();
  const [uploaded, setUploaded] = useState('');
  const [urlLookup, setUrlLookup] = useState<Map<string, string>>(new Map());

  const viewerConfig = {
    plugin: 'Datagrid',
    theme: theme.palette.materialMode,
    plugin_config: {
      columns: {
        Time: {
          dateStyle: 'medium',
          timeStyle: 'short',
          timeZone: 'UTC',
        },
        Period: {
          column_size_override: 230,
        },
        Name: {
          column_size_override: 100,
        },
        Title: {
          column_size_override: 268,
        },
        Shares: {
          fixed: 0,
        },
        'JPY (mn)': {
          fixed: 0,
        },
        'Implied Shares': {
          fixed: 0,
        },
        From: {
          dateStyle: 'medium',
          timeStyle: 'disabled',
        },
        To: { 
          dateStyle: 'medium',
          timeStyle: 'disabled'
        }
      }
    },
    columns: [
      'Time',
      'Ticker',
      'Name',
      'Title',
      'Exch',
      'Type',
      'From',
      'To',
      'Shares',
      'JPY (mn)',
      'Implied Shares',
      'Days 90 ADV',
      '% Float',
      '% Shs Out',
    ],
    sort: [
      ['time', 'desc'],
      ['code', 'desc']
    ],
    expressions: [
      '//Time\n"time"',
      '//Ticker\n"code"',
      '//Name\n"jap_name"',
      '//Title\n"title"',
      '//Exch\n"exch"',
      '//Type\n"type"',
      '//Period\n"date"',
      '//Shares\n"share_count"',
      '//JPY (mn)\n"share_value"/1e6',
      '//Implied Shares\n"implied_share_count"',
      '//Days 90 ADV\n"days_90_adv"',
      '//% Float\n"pct_float"*100',
      '//% Shs Out\n"pct_shares_out"*100',
      '//From\n"from"',
      '//To\n"to"',
    ]
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = '/api/tyo_bb_announcements';

    const response = await fetch(url);
    setUploaded(response.headers.get('uploaded') || '');
    const buffer = await response.arrayBuffer();
    const table = await worker.table(buffer);

    if (viewerRef.current) {
      await viewerRef.current.load(table);
      await viewerRef.current.restore(viewerConfig as any);
    }

    const view = await table.view({
      columns: ['code', 'url'],
    });
    const columns = await view.to_json();
    setUrlLookup(new Map(columns.map(x => [x.code, x.url])) as any);
  }

  useEffect(() => {
    viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  }, [viewerConfig]);
  
  useEffect(() => {
    const handlePerspectiveClick = (e: any) => { window.open(urlLookup.get(e.detail.row.Ticker), '_blank'); };
    viewerRef.current?.addEventListener('perspective-click', handlePerspectiveClick);
    return () => viewerRef.current?.removeEventListener('perspective-click', handlePerspectiveClick);
  }, [viewerRef, urlLookup]);

  return (
    <Box sx={{ width: '100%' }} position='relative'>
      <Typography align='center' variant='h2' >TDNET Buyback Announcements</Typography>
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        <perspective-viewer style={{ height: '75vh' }} ref={viewerRef} />
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
      <Typography align='right' variant='caption' display={'block'} marginY={0}>{`(Last updated: ${uploaded})`}</Typography>
    </Box>
  )
}

function TyoChs() {
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();

  const viewerConfig = {
    plugin: 'Datagrid',
    theme: theme.palette.materialMode,
    plugin_config: {
      columns: {

      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = './tyo_summary_ch.feather';

    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    const table = await worker.table(buffer);

    if (viewerRef.current) {
      await viewerRef.current.load(table);
      await viewerRef.current.restore(viewerConfig as any);
    }
  }

  useEffect(() => {
    viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  }, [viewerConfig]);

  return (
    <Box sx={{ width: '100%' }} position='relative'>
      <Typography align='center' variant='h2' >Crossholdings Summary</Typography>
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        <perspective-viewer style={{ height: '75vh' }} ref={viewerRef} />
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
    </Box>
  )
}

function SydGss() {
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();
  const [uploaded, setUploaded] = useState('');

  const viewerConfig = {
    plugin: 'Datagrid',
    theme: theme.palette.materialMode,
    plugin_config: {
      columns: {

      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const url = '/api/syd_gss';

    const response = await fetch(url);
    setUploaded(response.headers.get('uploaded') || '');
    const buffer = await response.arrayBuffer();
    const table = await worker.table(buffer);

    if (viewerRef.current) {
      await viewerRef.current.load(table);
      await viewerRef.current.restore(viewerConfig as any);
    }
  }

  useEffect(() => {
    viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  }, [viewerConfig]);

  return (
    <Box sx={{ width: '100%' }} position='relative'>
      <Typography align='center' variant='h2' >Daily Gross Short Sales</Typography>
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        <perspective-viewer style={{ height: '75vh' }} ref={viewerRef} />
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
      <Typography align='right' variant='caption' display={'block'} marginY={0}>{`(Last updated: ${uploaded})`}</Typography>
    </Box>
  )
}

function SydSpr() {
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();
  const [uploaded, setUploaded] = useState('');
  const [dt, setData] = useState<ColumnTable>();
  const [filters, setFilters] = useState([] as string[]);
  const [vegaView, setVegaView] = useState<embed.Result['view']>();
  const [options, setOptions] = useState<{Ticker: string, Name: string}[]>([]);

  // const viewerConfig = {
  //   plugin: 'Y Line',
  //   theme: theme.palette.materialMode,
  //   plugin_config: {
  //     columns: {
  //       'Date': {
  //         timeStyle: 'disabled',
  //         dateStyle: 'medium'
  //       }
  //     }
  //   },
  //   group_by: ['Date'],
  //   split_by: ['Ticker'],
  //   columns: ['% of Total Product in Issue Reported as Short Positions'],
  //   filter: [['Ticker', '==', 'BHP']]
  // }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   const url = './syd_spr.feather';

  //   const response = await fetch(url);
  //   setUploaded(response.headers.get('uploaded') || '');
  //   const buffer = await response.arrayBuffer();
  //   const table = await worker.table(buffer);

  //   if (viewerRef.current) {
  //     await viewerRef.current.load(table);
  //     await viewerRef.current.restore(viewerConfig as any);
  //   }
  // }

  // useEffect(() => {
  //   viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  // }, [viewerConfig]);

  const fetchData = async () => {
    const url = './syd_spr.feather';

    const response = await fetch(url);
    setUploaded(response.headers.get('uploaded') || '');
    const buffer = await response.arrayBuffer();
    setData(aq.fromArrow(buffer));
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dt) {
      setOptions(dt.rollup({ rows: d => op.array_agg_distinct(op.row_object('Ticker', 'Name')) }).get('rows', 0));
    }
  }, [dt]);

  const makeTable = async () => {
    if (dt) {
      const buffer = dt
                  // .filter(aq.escape((d:any) => op.has(filters, d['Ticker'])))
                  .toArrow();

      const vlSpec = {
        width: 'container',
        height: 'container',
        // $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
        data: {
          values: buffer,
        },
        params: [
          { name: 'filtersSignal', value: [] as string[] },
        ],
        transform: [
          { filter: 'indexof(filtersSignal, datum.Ticker) != -1' },
          { 
            calculate: 'format(datum["Reported Short Positions"], ",")+"/"+format(datum["Reported Short Positions"]/datum["% of Total Product in Issue Reported as Short Positions"]*100, ",.0f")',
            as: 'Shs Short Frac'
          },
        ],
        encoding: {
          x: {
            field: 'Date',
            type: 'temporal',
            axis: {
              grid: false,
              ticks: false,
            }
          },
        },
        layer: [
          {
            encoding: {
              y: {
                field: '% of Total Product in Issue Reported as Short Positions',
                type: 'quantitative',
                title: '% Short',
                axis: {
                  grid: false,
                  ticks: false,
                }
              },
              color: { field: 'Ticker', type: 'nominal' },
            },
            layer: [
              {
                params: [{
                  name: 't_hover',
                  select: 'point',
                  fields: ['Ticker'],
                  on: 'mouseover',
                  clear: 'mouseout',
                  nearest: false,
                }],
                mark: { type: 'line', strokeWidth: 9, stroke: 'transparent' }
              },
              { mark: 'line' },
              {
                transform: [{
                  filter: {
                    and: [ {param: 'd_hover', empty: false } ]
                  }
                }],
                mark: 'point'
              },
            ]
          },
          {
            mark: 'rule',
            transform: [
              { filter: { param: 't_hover' } }
            ],
            encoding: {
              opacity: {
                condition: { value: 0.3, param: 'd_hover', empty: false },
                value: 0,
              },
              tooltip: [
                {field: '% of Total Product in Issue Reported as Short Positions', type: 'quantitative', title: '% Short'},
                {field: 'Date', type: 'temporal'},
                {field: 'Ticker', type: 'nominal'},
                {field: 'Shs Short Frac', type: 'nominal'},
              ],
            },
            params: [
              {
                name: 'd_hover',
                select: {
                  type: 'point',
                  fields: ['Date'],
                  nearest: true,
                  on: 'mouseover',
                  clear: 'mouseout',
                }
              },
            ]
          }
        ]
      };

      const vlOptions = {
        renderer: 'svg',
        mode: 'vegaLite',
        theme: 'dark',
        actions: true,
      }

      // @ts-ignore
      embed.default('#vis', vlSpec, vlOptions).then( r => setVegaView(r.view) );
    }
  };

  useEffect(() => {
    makeTable();
  }, [dt])

  useEffect(() => {
    console.log('HMMMM');
    if (vegaView) {
      vegaView.signal('filtersSignal', filters).runAsync().then(v => setVegaView(v) );
    }
  }, [filters])

  const filterTickers = (e: SyntheticEvent, val: any[]) => {
    setFilters(val.map(x => x['Ticker']));
  }

  return (
    <Box sx={{ width: '100%' }} position='relative'>
      <Typography align='center' variant='h2' >Short Position Reports</Typography>
      <Autocomplete
        sx={{
          margin: '1em auto 1em auto',
          width: '30%'
        }}
        multiple
        options={options}
        getOptionLabel={(option) => `${option['Ticker']}: ${option['Name']}`}
        filterSelectedOptions
        onChange={filterTickers}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Tickers...'
          />
        )}
      />
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        {/* <perspective-viewer style={{ height: '75vh' }} ref={viewerRef} /> */}
        <div id='vis' style={{ height: '75vh' }}></div>
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
      <Typography align='right' variant='caption' display={'block'} marginY={0}>{`(Last updated: ${uploaded})`}</Typography>
    </Box>
  )
}

function HkgAh1() {
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();
  const [uploaded, setUploaded] = useState('');
  const [dt, setData] = useState<ColumnTable>();
  const [filters, setFilters] = useState([] as string[]);
  const [vegaView, setVegaView] = useState<embed.Result['view']>();
  const [options, setOptions] = useState<{hCode: string, constituentName: string, industry: string}[]>([]);

  
  const fetchData = async () => {
    // const url = './ah_premium_aug.feather';
    const url = '/api/hkg_ahp_aug';

    const response = await fetch(url);
    setUploaded(response.headers.get('uploaded') || '');
    const buffer = await response.arrayBuffer();
    setData(aq.fromArrow(buffer));
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dt) {
      setOptions(dt.rollup({ rows: d => op.array_agg_distinct(op.row_object('hCode', 'constituentName', 'industry')) }).get('rows', 0));
    }
  }, [dt]);

  const makeTable = async () => {
    if (dt) {
      const buffer = dt
                  // .filter(aq.escape((d:any) => op.has(filters, d['Ticker'])))
                  .toArrow();

      const vlSpec = {
        $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
        data: {
          values: buffer,
        },
        params: [
          { name: 'filtersSignal', value: [] as string[] },
        ],
        transform: [
          { 
            calculate: 'datum.hkVol+datum.cnVol',
            as: 'vol'
          },
          {
            groupby: ['Date', 'industry'],
            joinaggregate: [
              {
                op: 'mean',
                field: 'zscore',
                as: 'meanzscore'
              },
              {
                op: 'sum',
                field: 'hkVol',
                as: 'sumHkVol'
              },
              {
                op: 'sum',
                field: 'cnVol',
                as: 'sumCnVol'
              }
            ],
          },
          { calculate: 'datum.sumHkVol-datum.hkVol', as: 'sumHkVol' },
          { calculate: 'datum.sumCnVol-datum.cnVol', as: 'sumCnVol' },
          { fold: ['hkVol', 'cnVol', 'sumHkVol', 'sumCnVol'], as:['volKey', 'volValue'] },
          { filter: 'indexof(filtersSignal, datum.hCode) != -1' }
        ],
        encoding: {
          x: {
            field: 'Date',
            type: 'temporal',
          },
        },
        columns: 1,
        concat: [
          {
            width: 800,
            height: 300,
            encoding: {
              x: {
                field: 'Date',
                type: 'temporal',
                axis: {
                  grid: false,
                  ticks: false,
                }
              },
            },
            layer: [
              {
                mark: {type: 'line', tooltip: true},
                encoding: {
                  y: {
                    field: 'meanzscore',
                    type: 'quantitative',
                    axis: {
                      grid: false,
                      ticks: false,
                    }
                  },
                  color: { value: 'orange' },
                  opacity: { value: 0.2 }
                }
              },
              {
                params: [{
                  name: 'grid', select: 'interval', bind: 'scales'
                }],
                mark: {type: 'line', tooltip: true},
                encoding: {
                  y: {
                    field: 'zscore',
                    type: 'quantitative',
                    title: 'AH Premium Z-Score',
                    axis: {
                      grid: false,
                      ticks: false,
                    }
                  },
                  color: { field: 'hCode', type: 'nominal' },
                },
              },
            ],
          },
          {
            width: 800,
            height: 300,
            encoding: {
              x: {
                field: 'Date',
                type: 'temporal',
                axis: {
                  grid: false,
                  ticks: false,
                }
              },
            },
            layer: [
              {
                mark: 'bar',
                encoding: {
                  y: {
                    aggregate: 'sum',
                    field: 'volValue',
                    title: 'Volume traded',
                    axis: {
                      grid: false,
                      ticks: false
                    }
                  },
                  color: { field: 'volKey', type: 'nominal' }
                }
              }
            ]
          },
        ],
        resolve: {
          scale: {
            x: 'shared',
          }
        }
      };

      const vlOptions = {
        renderer: 'svg',
        mode: 'vegaLite',
        theme: 'dark',
        actions: true,
      }

      // @ts-ignore
      embed.default('#vis', vlSpec, vlOptions).then( r => setVegaView(r.view) );
    }
  };

  useEffect(() => {
    makeTable();
  }, [dt])

  useEffect(() => {
    if (vegaView) {
      vegaView.signal('filtersSignal', filters).runAsync().then(v => setVegaView(v) );
    }
  }, [filters])

  const filterTickers = (e: SyntheticEvent, val: any) => {
    if (!!val) {
      setFilters([val['hCode']]);
    } else {
      setFilters([]);
    }
  }

  return (
    <Box sx={{ width: '100%' }} position='relative'>
      <Typography align='center' variant='h2' >AH Premium Historical</Typography>
      <Autocomplete
        sx={{
          margin: '1em auto 1em auto',
          width: '30%'
        }}
        options={options}
        getOptionLabel={(option) => `${option['hCode']}: ${option['constituentName']} (${option['industry']})`}
        filterSelectedOptions
        onChange={filterTickers}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Tickers...'
          />
        )}
      />
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        {/* <perspective-viewer style={{ height: '75vh' }} ref={viewerRef} /> */}
        <div id='vis' style={{ height: '75vh', width: '100%' }}></div>
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
      <Typography align='right' variant='caption' display={'block'} marginY={0}>{`(Last updated: ${uploaded})`}</Typography>
    </Box>
  )
}

function TyoBlk() {
  const viewerRef = useRef<HTMLPerspectiveViewerElement>(null);
  const theme: PerspectiveTheme = useTheme();
  const [uploaded, setUploaded] = useState('');
  const [dt, setData] = useState<ColumnTable>();
  const [options, setOptions] = useState<{code: string, eng_name: string}[]>([]);
  const [filters, setFilters] = useState<Set<string>>();

  const viewerConfig = {
    plugin: 'Datagrid',
    theme: theme.palette.materialMode,
    plugin_config: {
      columns: {
        'Reported': {
          dateStyle: 'medium',
          timeStyle: 'disabled'
        },
        'Traded': {
          dateStyle: 'medium',
          timeStyle: 'disabled'
        },
        'Value (JPY mn)': {
          fixed: 0,
        },
        'Days 90 ADV': {
          fixed: 2,
        },
        '% Shs Out': {
          fixed: 2,
        },
        '% Float': {
          fixed: 2,
        }
      }
    },
    columns: [
      'Reported',
      'Traded',
      'Time',
      'Ticker',
      'Name (Jap)',
      'Name (Eng)',
      'PPS (JPY)',
      'Shares',
      'Value (JPY mn)',
      'Days 90 ADV',
      '% Shs Out',
      '% Float'
    ],
    expressions: [
      '//Reported\n"pub_date"',
      '//Traded\n"trading_date"',
      '//Time\n"trade_time"',
      '//Ticker\nstring("code")',
      '//Name (Jap)\n"jap_name"',
      '//Name (Eng)\n"eng_name"',
      '//PPS (JPY)\n"price"',
      '//Shares\n"shares"',
      '//Value (JPY mn)\n("jpy")/1e6',
      '//Days 90 ADV\n"days_90d_adv"',
      '//% Shs Out\nfloat("pct_shs_out")*100',
      '//% Float\nfloat("pct_float")*100'
    ]
  }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   const url = '/api/tyo_blk';

  //   const response = await fetch(url);
  //   setUploaded(response.headers.get('uploaded') || '');
  //   const buffer = await response.arrayBuffer();
  //   const table = await worker.table(buffer);

  //   if (viewerRef.current) {
  //     await viewerRef.current.load(table);
  //     await viewerRef.current.restore(viewerConfig as any);
  //   }
  // }

  const fetchData = async () => {
    const url = '/api/tyo_blk';

    const response = await fetch(url);
    setUploaded(response.headers.get('uploaded') || '');
    const buffer = await response.arrayBuffer();
    setData(aq.fromArrow(buffer));
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (dt) {
      setOptions(dt.orderby('code').rollup({ rows: d => op.array_agg_distinct(op.row_object('code', 'eng_name')) }).get('rows', 0));
    }
  }, [dt]);

  const makeTable = async () => {
    if (dt) {
      const buffer = dt
                  .filter(aq.escape((d:any) => !filters || (filters.size == 0) || op.has(filters, d['code'])))
                  .derive({
                    pub_date:  d => (op as any).parse_date(d!!['pub_date']),
                    trading_date:  d => (op as any).parse_date(d!!['trading_date'])
                  })
                  .toArrowBuffer().buffer;

      const table = await worker.table(buffer);

      if (viewerRef.current) {
        await viewerRef.current.load(table);
        await viewerRef.current.restore(viewerConfig as any);
      }
    }
  };

  useEffect(() => {
    makeTable();
  }, [dt, filters])

  useEffect(() => {
    viewerRef.current?.getTable(false).then(() => viewerRef.current?.restore(viewerConfig as any), () => { });
  }, [viewerConfig]);

  const filterTickers = (e: SyntheticEvent, val: any[]) => {
    setFilters(new Set(val.map(x => x['code'])));
  }

  return (
    <Box sx={{ width: '100%' }} position='relative'>
      <Typography align='center' variant='h2' >Super-Block Execution of Single-Issue Trading</Typography>
      <Autocomplete
        sx={{
          margin: '1em auto 1em auto',
          width: '30%'
        }}
        multiple
        options={options}
        getOptionLabel={(option) => `${option['code']}: ${option['eng_name']}`}
        filterSelectedOptions
        onChange={filterTickers}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder='Tickers...'
          />
        )}
      />
      <Box width={'100%'} display='flex' flexDirection={'column'} paddingX='2.5em'>
        <perspective-viewer style={{ height: '75vh' }} ref={viewerRef} />
        <Button sx={{ marginLeft: 'auto', marginRight: '1em', marginTop: '0.5em' }} onClick={() => downloadExcel(viewerRef)}>
          <DownloadIcon sx={{ marginRight: '0.2em' }} />Excel
        </Button>
      </Box>
      <Typography align='right' variant='caption' display={'block'} marginY={0}>{`(Last updated: ${uploaded})`}</Typography>
    </Box>
  )
}

function App() {
  const routes = createBrowserRouter([
    {
      path: '/',
      element: <SideMenu />,
      children: [
        {
          path: 'tyo-si',
          element: <TyoSI />
        },
        {
          path: 'tyo-bb',
          element: <TyoBb />
        },
        {
          path: 'tyo-bb1',
          element: <TyoBb1 />
        },
        {
          path: 'tyo-ch',
          element: <TyoCh />
        },
        {
          path: 'tyo-chs',
          element: <TyoChs />
        },
        {
          path: 'tyo-on',
          element: <TyoOn />
        },
        {
          path: 'hkg-ah',
          element: <HkgAh />
        },
        {
          path: 'hkg-bb',
          element: <HkgBb />
        },
        {
          path: 'nyc-13',
          element: <Nyc13 />
        },
        {
          path: 'syd-gss',
          element: <SydGss />
        },
        {
          path: 'syd-spr',
          element: <SydSpr />
        },
        {
          path: 'tyo-blk',
          element: <TyoBlk />
        },
        {
          path: 'hkg-ah1',
          element: <HkgAh1 />
        }
      ]
    },
  ])
  return routes;
}

export default App;

// const vlSpec = {
//   width: 'container',
//   height: 'container',
//   // $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
//   data: {
//     values: buffer,
//   },
//   params: [
//     { name: 'filtersSignal', value: [] as string[] },
//   ],
//   transform: [
//     { filter: 'indexof(filtersSignal, datum.Ticker) != -1' },
//     { 
//       calculate: 'format(datum["Reported Short Positions"], ",")+"/"+format(datum["Reported Short Positions"]/datum["% of Total Product in Issue Reported as Short Positions"]*100, ",.0f")',
//       as: 'Shs Short Frac'
//     },
//   ],
//   encoding: {
//     x: {
//       field: 'Date',
//       type: 'temporal',
//       axis: {
//         grid: false,
//         ticks: false,
//       }
//     },
//     y: {
//       field: '% of Total Product in Issue Reported as Short Positions',
//       type: 'quantitative',
//       title: '% Short',
//       axis: {
//         grid: false,
//         ticks: false,
//       }
//     },
//     color: {field: 'Ticker', type: 'nominal'},
//   },
//   layer: [
//     // {
//       // params: [
//       //   {
//       //     name: 'd_hover',
//       //     value: [],
//       //     select: {
//       //       type: 'point',
//       //       fields: ['Date'],
//       //       on: 'mouseover',
//       //       clear: 'mouseout',
//       //       nearest: true,
//       //     }
//       //   },
//       //   // {
//       //   //   name: 't_hover',
//       //   //   value: [],
//       //   //   select: {
//       //   //     type: 'point',
//       //   //     fields: ['Ticker'],
//       //   //     on: 'mouseover',
//       //   //     clear: 'mouseout',
//       //   //     nearest: true,
//       //   //   }
//       //   // }
//       // ],
//       // encoding: {
//       //   tooltip: [
//       //     {field: '% of Total Product in Issue Reported as Short Positions', type: 'quantitative', title: '% Short'},
//       //     {field: 'Date', type: 'temporal'},
//       //     {field: 'Ticker', type: 'nominal'},
//       //     {field: 'Shs Short Frac', type: 'nominal'},
//       //   ]
//       // },
//       // mark: { type: 'point', strokeWidth: 20, stroke: 'transparent' },
//     // },
//     { mark: 'line' },
//     { transform: [{filter: {param: 'd_hover', empty: false}}], mark: 'point'},
//     {
//       mark: 'rule',
//       encoding: {
//         opacity: {
//           condition: { param: 'd_hover', empty: false, value: 0.3 },
//           value: 0,
//         },
//         tooltip: [
//           {field: '% of Total Product in Issue Reported as Short Positions', type: 'quantitative', title: '% Short'},
//           {field: 'Date', type: 'temporal'},
//           {field: 'Ticker', type: 'nominal'},
//           {field: 'Shs Short Frac', type: 'nominal'},
//         ]
//       },
//       params: [
//         {
//           name: 'd_hover',
//           value: [],
//           select: {
//             type: 'point',
//             fields: ['Date'],
//             on: 'mouseover',
//             clear: 'mouseout',
//             nearest: true,
//           }
//         },
//         // {
//         //   name: 't_hover',
//         //   value: [],
//         //   select: {
//         //     type: 'point',
//         //     fields: ['Ticker'],
//         //     on: 'mouseover',
//         //     clear: 'mouseout',
//         //     nearest: true,
//         //   }
//         // }
//       ],
//     }
//   ]
//   // layer: [
//   //   {
//   //     layer: [
//   //       { mark: 'line' },
//   //       { transform: [
//   //           {
//   //             filter: {
//   //               and: [ { param: 'd_hover', empty: false } ]
//   //             }
//   //           }
//   //         ],
//   //         mark: 'point'
//   //       },
//   //     ]
//   //   },
//   //   {
//   //     mark: 'rule',
//   //     encoding: {
//   //       opacity: {
//   //         condition: {param: 'd_hover', empty: false, value: 0.3},
//   //         value: 0,
//   //       },
//   //       tooltip: [
//   //         {field: '% of Total Product in Issue Reported as Short Positions', type: 'quantitative', title: '% Short'},
//   //         {field: 'Date', type: 'temporal'},
//   //         {field: 'Ticker', type: 'nominal'},
//   //         {field: 'Shs Short Frac', type: 'nominal'},
//   //       ]
//   //     },
//   //     params: [
//   //       { 
//   //         name: 'd_hover',
//   //         select: {
//   //           type: 'point',
//   //           fields: ['Date'],
//   //           nearest: true,
//   //           on: 'mousemove',
//   //         }
//   //       },
//   //     ]
//   //   },
//   //   {
//   //     mark: { type: 'point', strokeWidth: 0, stroke: 'transparent' },
//   //     params: [
//   //       { 
//   //         name: 't_hover',
//   //         select: {
//   //           type: 'point',
//   //           fields: ['Ticker'],
//   //           nearest: true,
//   //           on: 'mousemove',
//   //         }
//   //       },
//   //     ]
//   //   },
//   // ]
// };
